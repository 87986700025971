import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

const Admin = () => import("@/views/Admin");
const Login = () => import("@/views/Login");
const Dashboard = () => import("@/views/Dashboard");
const DashboardHour = () => import("@/views/DashboardHour");
const DashboardMarkHour = () => import("@/views/DashboardMarkHour");
const Particplants = () => import("@/views/Particplants");
const Status = () => import("@/views/Status");
const SubStatus = () => import("@/views/SubStatus");
const Contact = () => import("@/views/Contact");
const Attribute = () => import("@/views/Attribute");
const AttributeGroup = () => import("@/views/AttributeGroup");

const ParticplantsUpdateAction = () =>
  import("@/views/ParticplantsUpdateAction");
const CreateParticplants = () => import("@/views/CreateParticplants");
const UpdatePhotoParticplants = () => import("@/views/UpdatePhotoParticplants");
const DataSourceCfg = () => import("@/views/DataSourceCfg");
const OperatorAction = () => import("@/views/OperatorAction");
const EventAction = () => import("@/views/EventAction");
const TSAction = () => import("@/views/TSAction");
const DeviceAction = () => import("@/views/DeviceAction");
const ParticplantsAction = () => import("@/views/ParticplantsAction");
const ParticplantsCreateAction = () =>
  import("@/views/ParticplantsCreateAction");
const Device = () => import("@/views/Device");
const ChangePassword = () => import("@/views/ChangePassword");
const ImportParticplants = () => import("@/views/ImportParticplants");
const GaleryFiles = () => import("@/views/GaleryFiles");
const GaleryUser = () => import("@/views/GalleryUser");
const Galery = () => import("@/views/Galery");
const ExportParticplants = () => import("@/views/ExportParticplants");
const Session = () => import("@/views/Session");
const Contractor = () => import("@/views/Contractor");
const CreateContractor = () => import("@/views/CreateContractor");
const ViewContractor = () => import("@/views/ViewContractor");

const ContractorParticplants = () => import("@/views/ContractorParticplants");
const ContractorPacket = () => import("@/views/ContractorPacket");
const CreateContractorPacket = () => import("@/views/CreateContractorPacket");
const ContractorPacketConfig = () => import("@/views/ContractorPacketConfig");
const CreateContractorPacketConfig = () =>
  import("@/views/CreateContractorPacketConfig");
const ContractorAnketaConfig = () => import("@/views/ContractorAnketaConfig");
const CreateContractorAnketaConfig = () =>
  import("@/views/CreateContractorAnketaConfig");
const ContractorsSendtoReview = () => import("@/views/ContractorsSendtoReview");
const TS = () => import("@/views/TS");
const CreateTS = () => import("@/views/CreateTS");
const ContractorTs = () => import("@/views/ContractorTS");
const CreateContractorExtSession = () =>
  import("@/views/CreateContractorExtSession");
const ContractorTSAcess = () => import("@/views/ContractorTSAcess");
const FoodService = () => import("@/views/FoodService");
const ParticplantsEmailSender = () => import("@/views/ParticplantsEmailSender");
const CreateParticplantsEmailSender = () =>
  import("@/views/CreateParticplantsEmailSender");
const MarkInfo = () => import("@/views/MarkInfo");
const MarkInfoCount = () => import("@/views/MarkInfoCount");
const DashboardMark = () => import("@/views/DashboardMark");
const Ticket = () => import("@/views/Ticket");
const RegDevice = () => import("@/views/RegDevice");
const RegEvent = () => import("@/views/RegEvent");
const RunAccelEvent = () => import("@/views/RunAccelEvent");
const CheckParticplants = () => import("@/views/CheckParticplants");
const DeviceStatusConfig = () => import("@/views/DeviceStatusConfig");

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Главная",
      component: TheContainer,
      children: [
        {
          path: "dashboard",
          name: "Статистика по аккредитации",
          component: Dashboard,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "dashboardhour",
          name: "Статистика по аккредитации (по часам)",
          component: DashboardHour,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "dashboardmark",
          name: "Статистика по отметкам",
          component: DashboardMark,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "dashboardmarkhour",
          name: "Статистика по отметкам (по часам)",
          component: DashboardMarkHour,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "admin",
          name: "Пользователи",
          component: Admin,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "event/:id",
          name: "Вход",
          component: Login,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "login",
          name: "Вход",
          component: Login,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "participants",
          name: "Посетители",
          component: Particplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "checkparticplants",
          name: "Проверка целостности данных посетителей",
          component: CheckParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "addparticipants",
          name: "Новый посетитель",
          component: CreateParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "editparticipants/:id",
          name: "Редактировать посетителя",
          component: CreateParticplants,
          props: (route) => ({ token: route.query.token }),
        },

        {
          path: "fixparticipantsimport/:importid/:id/",
          name: "Редактировать посетителя",
          component: CreateParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "updatephotoparticipants/:id",
          name: "Редактировать фото посетителя",
          component: UpdatePhotoParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "status",
          name: "Статусы",
          component: Status,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "substatus/:id",
          name: "Субстатус",
          component: SubStatus,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "statussync/:id",
          name: "Параметры синхронизации статуса",
          component: DeviceStatusConfig,
          props: (route) => ({ token: route.query.token }),
        },        
        {
          path: "contact",
          name: "Контакты",
          component: Contact,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contactbygroup/:groupid",
          name: "Контакты по группе",
          component: Contact,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "attribute",
          name: "Атрибуты",
          component: Attribute,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "attributebygroup/:groupid",
          name: "Аттрибуты по группе",
          component: Attribute,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "attributegroup",
          name: "Группы Атрибутов",
          component: AttributeGroup,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "datasourcecfg",
          name: "Конфигурация источников данных на мерприятии",
          component: DataSourceCfg,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "operatoraction/:id",
          name: "Лог пользователя",
          component: OperatorAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "eventaction",
          name: "Лог мероприятия",
          component: EventAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "eventactionfull",
          name: "Лог мероприятия (полная версия)",
          component: EventAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "tsaction/:id",
          name: "Лог ТС",
          component: TSAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "deviceaction/:id",
          name: "Лог устройства",
          component: DeviceAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "participantsaction/:id",
          name: "Лог посетителей",
          component: ParticplantsAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "participantsupdateaction/:id",
          name: "Лог изменений посетителя",
          component: ParticplantsUpdateAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "participantscreateaction/:id",
          name: "Лог создания посетителя",
          component: ParticplantsCreateAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "device",
          name: "Устройства",
          component: Device,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "changepassword",
          name: "Сменить пароль",
          component: ChangePassword,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "importparticipants",
          name: "Импорт посетителей",
          component: ImportParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "exportparticipants",
          name: "Экспорт посетителей",
          component: ExportParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "galery",
          name: "Распознования",
          component: GaleryFiles,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "galeryparticplants/:id",
          name: "Люди по фото",
          component: GaleryUser,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "participantsgalery/:id",
          name: "Фото людей",
          component: Galery,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "session",
          name: "Сессии",
          component: Session,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractors",
          name: "Управление квотами",
          component: Contractor,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "createcontractor",
          name: "Создание квоты",
          component: CreateContractor,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "viewcontractor/:id",
          name: "Просмотр квоты",
          component: ViewContractor,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipants",
          name: "Управление анкетами",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantsdup",
          name: "Дубликаты анкет",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantsdupfio",
          name: "Дубликаты анкет по ФИО",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantswaitmoderation",
          name: "Анкеты на модерации",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantssearch",
          name: "Поиск по анкетам",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantsdupview/:dupid",
          name: "Дубликаты анкет",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantssearchfi/:firstname/:lastname",
          name: "Поиск по фамилии и имени",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantssearchf/:lastname",
          name: "Поиск по фамилии",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantssearchid/:idview",
          name: "Поиск по ID",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantssearchpasport/:pasporthash",
          name: "Поиск по паспортным данным",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantssearchpasport/:birthdate/:pasportseria/:pasportnumber",
          name: "Поиск по паспортным данным",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantssearchbirthdate/:birthdate",
          name: "Поиск по дате рождения",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantssearchfil/:firstnamel/:lastnamel",
          name: "Поиск по фамилии и имени",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorparticipantssearchfl/:lastnamel",
          name: "Поиск по фамилии",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorpacketparticipants/:packetid",
          name: "Управление анкетами",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorpacketridparticipants/:packetid/:rid",
          name: "Управление анкетами",
          component: ContractorParticplants,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorpacket",
          name: "Модерация анкет (пакеты)",
          component: ContractorPacket,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "createcontractorpacket",
          name: "Создать пакет",
          component: CreateContractorPacket,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorpacketconfig",
          name: "Настройка выгрузки пакетов",
          component: ContractorPacketConfig,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "createcontractorpacketconfig",
          name: "Создание настроек пакетов",
          component: CreateContractorPacketConfig,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "duplicatecontractorpacketconfig/:id",
          name: "Создание настроек пакетов",
          component: CreateContractorPacketConfig,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractoranketaconfig",
          name: "Настройка квот",
          component: ContractorAnketaConfig,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "createcontractoranketaconfig",
          name: "Создание настроек квот",
          component: CreateContractorAnketaConfig,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "duplicatecontractoranketaconfig/:srcid",
          name: "Создание настроек квот",
          component: CreateContractorAnketaConfig,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorssendtoreview/:id",
          name: "Отправить на исправление",
          component: ContractorsSendtoReview,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "createts/:pid",
          name: "Создать ТС",
          component: CreateTS,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "updatets/:id",
          name: "Обновить ТС",
          component: CreateTS,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "updatets/:id/:pid",
          name: "Обновить ТС",
          component: CreateTS,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "ts",
          name: "ТС",
          component: TS,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "tsparticpants/:pid",
          name: "ТС",
          component: TS,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractorts",
          name: "Модерация заявок на ТС",
          component: ContractorTs,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "createcontractorextsession",
          name: "Создание внешнего источника заявок",
          component: CreateContractorExtSession,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contractortsaccess/:id",
          name: "Печать ТС",
          component: ContractorTSAcess,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "foodservice",
          name: "Список (питание)",
          component: FoodService,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "particplantsemailsender",
          name: "Рассылки",
          component: ParticplantsEmailSender,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "createparticplantsemailsender",
          name: "Создание рассылки почты участникам",
          component: CreateParticplantsEmailSender,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "updateparticplantsemailsender/:id",
          name: "Обновление рассылки почты участникам",
          component: CreateParticplantsEmailSender,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "markinfo",
          name: "Хранилище отметок",
          component: MarkInfo,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "markinfocount",
          name: "Отметки",
          component: MarkInfoCount,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "ticket",
          name: "Создание билетов",
          component: Ticket,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "devreg",
          name: "Регистрация устройства",
          component: RegDevice,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "eventreg",
          name: "Регистрация мероприятия",
          component: RegEvent,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "eventrun",
          name: "Запуск ускорителя мероприятия",
          component: RunAccelEvent,
          props: (route) => ({ token: route.query.token }),
        },
      ],
    },
  ];
}
